import { createUseStorage } from 'packages/use-storage';

export const useAcpLocalStorage = createUseStorage('local', {
  prefix: 'acp_',
  nsUtilStyleEncoding: true
});
export const useAcpSessionStorage = createUseStorage('session', {
  prefix: 'acp_',
  nsUtilStyleEncoding: true
});
